import { navigateToUrl } from 'single-spa';
import manifest from '../../public/manifest.json';
import { portalShellManager } from './PortalShell';
const appsWithoutRedirect = ['@uipath/portal', '@uipath/global-app'];
export const PR_PREVIEW_REDIRECT_KEY = '__pr_preview_redirectAfterLogin__';
export function setOverrides() {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    if (searchParams.get('spa-reset')) {
        window.importMapOverrides.resetOverrides();
        localStorage.removeItem('devtools');
    }
    searchParams.getAll('spa-module').forEach(module => {
        const { name, path } = JSON.parse(module);
        if (Object.keys(manifest).includes(name) && !appsWithoutRedirect.includes(name)) {
            localStorage.setItem(PR_PREVIEW_REDIRECT_KEY, name);
        }
        window.importMapOverrides.addOverride(name, path);
        localStorage.setItem('devtools', 'true');
    });
}
export function redirectToPreview() {
    const portalShell = portalShellManager.getPortalShell();
    const shouldRedirectTo = localStorage.getItem(PR_PREVIEW_REDIRECT_KEY);
    if (portalShell && shouldRedirectTo) {
        if (manifest[shouldRedirectTo]) {
            const { activeWhen } = manifest[shouldRedirectTo];
            const orgName = activeWhen.includes(':accountId') ? portalShellManager.getOrganizationName() + '/' : '';
            const tenantName = activeWhen.includes(':tenantId') ? portalShellManager.getTenantName() + '/' : '';
            const serviceName = shouldRedirectTo.split('/')[1] + '_';
            navigateToUrl(`/${orgName}${tenantName}${serviceName}`);
            localStorage.removeItem(PR_PREVIEW_REDIRECT_KEY);
        }
    }
}
