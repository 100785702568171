import { navigateToUrl } from 'single-spa';
/**
 * The ID used for the Apollo shell element.
 */
export const APOLLO_SHELL_ID = 'apollo-shell';
/**
 * Manages the Portal Shell functionality.
 */
export class PortalShellManager {
    constructor() { }
    /**
     * Gets the singleton instance of PortalShellManager.
     * @returns The PortalShellManager instance.
     */
    static getInstance() {
        if (!PortalShellManager.instance) {
            PortalShellManager.instance = new PortalShellManager();
        }
        return PortalShellManager.instance;
    }
    /**
     * Gets the Apollo shell component.
     * @returns The Apollo shell component or null if not found.
     */
    getApShellComponent() {
        return document.getElementById(APOLLO_SHELL_ID);
    }
    /**
     * Sets an attribute on the Apollo shell element.
     * @param attribute - The name of the attribute to set.
     * @param value - The value to set for the attribute.
     */
    setApShellAttribute(attribute, value) {
        const shell = this.getApShellComponent();
        if (shell) {
            shell.setAttribute(attribute, value);
        }
        else {
            console.error(`Unable to find ap-shell element to set attribute ${attribute} with value ${value}`);
        }
    }
    /**
     * Initializes the Portal Shell with the current organization.
     */
    initializeShell() {
        const org = window.location.pathname.split('/')[1];
        if (!org || org.endsWith('_')) {
            return;
        }
        window.PortalShell.initialize({
            devProperties: {
                organizationStandardUrl: `${window.__ROOT_CONFIG_ENV__.PORTAL_ORIGIN}`,
                organizationName: org.endsWith('_') ? undefined : org,
                mode: 'dev-cloud',
                isMFERoot: true,
            },
        });
    }
    /**
     * Creates and initializes the Apollo shell element.
     */
    createShell() {
        if (document.querySelector('ap-shell')) {
            return;
        }
        const shell = document.createElement('ap-shell');
        shell.id = APOLLO_SHELL_ID;
        shell.setAttribute('hideShell', 'true');
        document.querySelector('main').appendChild(shell);
        if (window.PortalShell) {
            this.initializeShell();
        }
        else {
            document.addEventListener('portalShellLoaded', () => {
                this.initializeShell();
            });
        }
        document.addEventListener('serviceClicked', (event) => {
            this.setApShellAttribute('active', event.detail.serviceid);
            navigateToUrl(event.detail.url);
        });
    }
    /**
     * Gets the Portal Shell instance.
     * @returns The Portal Shell instance.
     */
    getPortalShell() {
        return window.PortalShell;
    }
    /**
     * Extracts organization and tenant information from the current route.
     * @returns An object containing the organization and tenant names.
     */
    getRouteOrgAndTenant() {
        const org = window.location.pathname.split('/')[1];
        const tenant = window.location.pathname.split('/')[2];
        const hasOrg = org && !org.endsWith('_');
        const hasTenant = hasOrg && tenant && !tenant.endsWith('_');
        return {
            org: hasOrg ? org : undefined,
            tenant: hasTenant ? tenant : undefined,
        };
    }
    /**
     * Gets the current organization name.
     * @returns The organization name or undefined if not available.
     */
    getOrganizationName() {
        var _a, _b, _c, _d;
        const shellOrg = (_d = (_c = (_b = (_a = this.getPortalShell()) === null || _a === void 0 ? void 0 : _a.AccountAndTenants) === null || _b === void 0 ? void 0 : _b.getCurrentAccountAndTenantsInfo) === null || _c === void 0 ? void 0 : _c.call(_b)) === null || _d === void 0 ? void 0 : _d.organization;
        if (shellOrg) {
            return shellOrg.name;
        }
        return this.getRouteOrgAndTenant().org;
    }
    /**
     * Gets the current tenant name.
     * @returns The tenant name or undefined if not available.
     */
    getTenantName() {
        var _a, _b;
        const shellTenant = (_b = (_a = this.getPortalShell()) === null || _a === void 0 ? void 0 : _a.AccountAndTenants) === null || _b === void 0 ? void 0 : _b.getTenant();
        if (shellTenant) {
            return shellTenant.name;
        }
        return this.getRouteOrgAndTenant().tenant;
    }
}
/**
 * The singleton instance of PortalShellManager.
 */
export const portalShellManager = PortalShellManager.getInstance();
