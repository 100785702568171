import { getMountedApps, navigateToUrl, } from 'single-spa';
import { portalShellManager } from './PortalShell';
export function addRootContainerListeners() {
    window.addEventListener('single-spa:before-routing-event', (evt) => {
        document.getElementById('single-spa-error-container').innerHTML = ``;
    });
    window.addEventListener('single-spa:routing-event', (evt) => {
        // This is to handle the initial default page transition to portal_ service.
        // This is needed when we don't have an org or a service_ in the url.
        if (getMountedApps().length === 0) {
            const location = window.location;
            const parts = location.pathname.split('/').filter(Boolean);
            if (parts.length < 2) {
                navigateToUrl('/portal_');
            }
        }
    });
}
export function updateRootContainerGlobals() {
    window.RootContainer = {
        portalDomain: `${window.__ROOT_CONFIG_ENV__.PORTAL_DOMAIN}`,
        portalShellManager,
    };
    // @deprecated -- remove after experiences changes
    window.__ROOT_CONTAINER__ = Object.assign(Object.assign({}, window.RootContainer), { org: portalShellManager.getOrganizationName(), tenant: portalShellManager.getTenantName() });
    portalShellManager.createShell();
}
